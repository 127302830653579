// @flow
import React from 'react';
import { Button } from '@riseart/common';
import { FacebookIcon } from '@riseart/icons';
import { libs as LIBS_CONFIG } from 'Config';

import { facebookBtnCls } from './Button.less';

type Props = {
  setToken: Function,
  children: React$Element<any>,
};

/**
 * FacebookButton
 *
 */
export const FacebookButton = (props: Props) => {
  /**
   * statusChangeCallback
   * @param response
   */
  const statusChangeCallback = (response) => {
    if (response.status === LIBS_CONFIG.facebook.statusConnected) {
      return props.setToken(response.authResponse.accessToken);
    }
    return props.setToken(null);
  };

  /**
   * checkLoginState
   * @param response
   */
  const checkLoginState = () => {
    window.FB.getLoginStatus((response) => {
      statusChangeCallback(response);
    });
  };

  /**
   * facebookLogin
   */
  const facebookLogin = () => {
    window.FB.login(
      (resp) => {
        checkLoginState(resp);
      },
      { scope: LIBS_CONFIG.facebook.scope },
    );
  };

  /**
   * @returns {React$Element<Button>}
   */
  return (
    <Button
      type="primary"
      size="medium"
      icon={<FacebookIcon />}
      className={facebookBtnCls}
      onClick={facebookLogin}
    >
      {props.children}
    </Button>
  );
};
